import { createRouter, createWebHistory } from 'vue-router'

let f = '/'

const routes = [
    {
        path: f,
        redirect: { name: 'home' }
    },
    {
        path: f+'web/core/',
        redirect: { name: 'home' },
        children: [
            {
                path: 'home',
                name: 'home',
                component: ()=> import('@/pages/Admin/Home')
            },
            {
                path: 'register',
                name: 'register',
                component: ()=> import('@/pages/Admin/Register')
            },
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
