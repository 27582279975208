import axios from 'axios'
import { ElMessage, ElNotification } from 'element-plus'


let instance = axios.create({
    baseURL: process.env.NODE_ENV == 'production' ? '' : 'http://127.0.0.1:9051'
})

let token = localStorage.getItem('token')
instance.interceptors.request.use((config)=>{
    if(token) {
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
})
let f = location.hostname.endsWith('kogwa.com') ? '/user-order' : ''
instance.interceptors.response.use((response)=>{
    // if(response.config.url == '/logout' && response.data.success){
    //     localStorage.clear();
    //     location.href = '/'
    // }
    if(Reflect.has(response.data, 'msg')) {
        ElMessage.info(response.data.msg)
    }
    if(response.data.token) {
        localStorage.setItem('token', response.data.token)
        location.href = f+'/'
    }
    if(response.data.type == 'INVALID_IDENTITY'){
        window.localStorage.removeItem('token');
        window.location.href = f+'/'
    }
    return response
},()=>{
    ElNotification({
        type: "error",
        title: '網絡錯誤',
        message: "請求中出現錯誤"
    })
})

export default instance